<template>
  <div>
    <b-row
      v-if="this.$store.state.app.isMaster(this.$store)"
      class=" mb-75  justify-content-end"
      no-gutters
    >
      <b-col
        sm="6"
        md="auto"
        class="pb-50 p-md-0 pr-sm-25 pb-sm-25"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="cusBtn mr-50"
          @click="openDialogRaw"
        >
          <span class="align-middle">ตั้งค่ากติกา</span>
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="auto"
        class="pb-50 p-md-0 pl-sm-25 pb-sm-25"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="cusBtn mr-50"
          @click="dialogEatForm = true"
        >
          <span class="align-middle">กำหนดเปอร์เซ็นกิน</span>
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="auto"
        class="pb-50 p-md-0 pr-sm-25 pt-sm-25"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="cusBtn mr-50"
          @click="dialogRateForm = true"
        >
          <span class="align-middle">ตั้งค่าอัตราจ่าย</span>
        </b-button>
      </b-col>
      <b-col
        sm="6"
        md="auto"
        class="pb-50 p-md-0 pl-sm-25 pt-sm-25"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="cusBtn"
          variant="primary"
          @click="dialogConditionForm = true"
        >
          <span class="align-middle">ตั้งค่าเงื่อนไขการเเทง</span>
        </b-button>
      </b-col>
    </b-row>
    <b-table
      responsive
      :fields="fields"
      :items="items"
      bordered
    >
      <template
        #cell(isLimit)="data"
      >
        <b-avatar
          v-if="data.value"
          variant="primary"
          square
        >
          <feather-icon icon="CheckIcon" />
        </b-avatar>
        <b-avatar
          v-else
          square
          variant="danger"
        >
          <feather-icon icon="XIcon" />
        </b-avatar>
      </template>
      <template #cell(status)="data">
        <b-button
          style="width: 200px;"
          pill
          :variant="statusTextColor[data.value]"
        >
          {{ statusText[data.value] }}
        </b-button>
      </template>
      <template #cell(action)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          @click="setting(data.item)"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span class="align-middle">เเก้ไข</span>
        </b-button>
      </template>
    </b-table>
    <b-modal
      v-model="dialogFormVisible"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      size="xs"
      :title="`ตั้งค่าหวยยี่กีรอบที่ ${edtForm.index}`"
      button-size="sm"
      @ok="SubmitForm"
      @hidden="modalTextError=''"
    >
      <b-form-input
        v-model="edtForm.percen"
        autofocus
        type="number"
        @keypress.enter="SubmitForm"
      />
      <small
        v-if="modalTextError !== ''"
        class="text-danger"
      >{{ modalTextError }}</small>
    </b-modal>

    <b-modal
      v-model="dialogRawForm"
      cancel-variant="secondary"
      ok-only
      ok-title="Save"
      centered
      size="lg"
      title="ตั้งค่ากติกา"
      button-size="sm"
      no-enforce-focus
      @ok="saveRaw"
    >
      <editor
        v-model="raw"
        :init="config"
      />
    </b-modal>

    <b-modal
      v-model="dialogEatForm"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      size="xs"
      title="กำหนดเปอร์เซ็นกินเหมือนกันหมด"
      button-size="sm"
      @ok="SubmitAllPercen"
      @hidden="modalTextError=''"
    >
      <b-form-input
        v-model="eatForm.percen"
        autofocus
        type="number"
        @keypress.enter="SubmitAllPercen"
      />
      <small
        v-if="modalTextError !== ''"
        class="text-danger"
      >{{ modalTextError }}</small>
    </b-modal>

    <b-modal
      v-model="dialogRateForm"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      size="lg"
      title="ตั้งค่าอัตราจ่าย"
      button-size="sm"
      hide-footer
    >
      <YeekeeRate
        :huay-rate="huayRate"
        @save="dialogRateForm =false"
      />
    </b-modal>

    <b-modal
      v-model="dialogConditionForm"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      size="lg"
      title="ตั้งค่าเงื่อนไขการเเทง"
      button-size="sm"
      hide-footer
    >
      <YeekeeCondition
        :huay-condition="huayRate"
        @save="dialogConditionForm =false"
      />

    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BButton, BAvatar, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Editor from '@tinymce/tinymce-vue'

import lottoService from '@/service/lottoService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import YeekeeRate from '@/views/components/YeekeeRate.vue'
import YeekeeCondition from '@/views/components/YeekeeCondition.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BButton,
    BAvatar,
    BFormInput,
    Editor,
    YeekeeRate,
    YeekeeCondition,
    BRow,
    BCol,
  },
  props: {
    type: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      config: {
        height: 600,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount textcolor colorpicker',
        ],
        toolbar:
            'undo redo | formatselect | '
            + ' bold italic backcolor forecolor | alignleft aligncenter '
            + ' alignright alignjustify | bullist numlist outdent indent |'
            + ' removeformat | help | fullscreen',
        content_css: [
          '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
          '//www.tiny.cloud/css/codepen.min.css',
        ],
        images_upload_handler(blobInfo, success) {
          success(
            `data:${blobInfo.blob().type};base64,${blobInfo.base64()}`,
          )
        },
      },
      dialogEatForm: false,
      dialogRateForm: false,
      dialogConditionForm: false,
      dialogRawForm: false,
      raw: '',
      eatForm: {
        percen: 100,
      },
      loading: false,
      statusText: [
        'ยังไม่ถึงเวลาเเทง',
        'อยู่ระหว่างเปิดให้เเทง',
        'หยุดพักชั่วคราว',
        'หมดเวลาเเทง',
        'อยู่ระหว่างคำนวนผล',
        'อยู่ระหว่างคำนวนเครดิต',
        'คำนวนเครดิตเรียบร้อยเเล้ว',
        'รอจ่ายเครดิต',
        'อยู่ระหว่างจ่ายเครดิต',
        'อยู่ระหว่างคืนโพย',
        'ปิดรอบเเทงเรียบร้อยเเล้ว',
      ],
      statusTextColor: [
        'cyan-7',
        'teal',
        'danger',
        'warning',
        'secondary',
        'secondary',
        'dark',
        'warning',
        'secondary',
        'secondary',
        'info-2',
      ],
      fields: [
        {
          key: 'index',
          label: 'Id',
        },
        {
          key: 'name',
          label: 'ชื่อ',
          class: 'text-nowrap',
        },
        {
          key: 'roundDate',
          label: 'รอบวันที่',
          class: 'text-center',
          thStyle: {
            'min-width': '110px',
          },
        },
        {
          key: 'roundStartDate',
          label: 'เวลาเปิดเเทง',
          class: 'text-center',
          thStyle: {
            'min-width': '140px',
          },
        },
        {
          key: 'roundEndDate',
          label: 'เวลาปิดเเทง',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        {
          key: 'lastUpdate',
          label: 'อัพเดตล่าสุด',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        {
          key: 'percen',
          label: 'เปอร์เซ็นกิน',
          class: 'text-center',
          thStyle: {
            'min-width': '130px',
          },
        },
        {
          key: 'status',
          label: 'สถานะ',
          class: 'text-center',
        },
        {
          key: 'action',
          label: 'ตั้งค่า',
          class: 'text-center text-nowrap',
        },
      ],
      items: [],
      dialogFormVisible: false,
      showEditor: true,
      edtForm: {
        id: '',
        index: 0,
        target: '',
        percen: 0,
      },
      huayRate: {},
      modalTextError: '',
      interval: null,
    }
  },
  mounted() {
    if (this.$store.state.app.isMaster(this.$store) === false) {
      this.fields.pop()
    }
    this.LoadYeeKee()
    lottoService
      .getAuthen('rate/condition/yeekee')
      .then(res => {
        if (res.status === 200) {
          this.huayRate = res.data
        }
      })

    this.interval = setInterval(() => {
      lottoService
        .getAuthen('yeekee/states')
        .then(res => {
          if (res.status === 200) {
            for (let i = 0; i < this.items.length; i += 1) {
              this.items[i].status = res.data[this.items[i].id]
            }
          }
        })
    }, 10000)
  },
  destroyed() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    async LoadYeeKee() {
      this.loading = true
      const res = await lottoService.getAuthen('yeekee')
      setTimeout(() => {
        if (res.status === 200) {
          this.items = res.data
          this.loading = false
        }
      }, 100)
    },
    saveRaw() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลกติกาใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            lottoService
              .postAuthen('yeekee/raw', this.raw)
              .then(res => {
                this.dialogRawForm = false
                if (res.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'success',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'บันทึกข้อมูสำเร็จ',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                      text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                    },
                  })
                }
              })
          }
        })
    },
    async openDialogRaw() {
      const res = await lottoService.getAuthen('yeekee/raw')
      if (res.status === 200) {
        this.raw = res.data
        this.dialogRawForm = true
        this.showEditor = true
      }
    },
    setting(row) {
      this.edtForm.id = row.id
      this.edtForm.index = row.index
      this.edtForm.target = row.target
      this.edtForm.percen = `${row.percen}`
      this.dialogFormVisible = true
    },
    async SubmitAllPercen(e) {
      e.preventDefault()
      if (this.eatForm.percen.length < 0) {
        this.modalTextError = 'คุณยังไม่ได้กรอกข้อมูล'
        return
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.eatForm.percen) || this.eatForm.percen[0] === '-') {
        this.modalTextError = 'กรุณากรอกตัวเลขให้ถูกต้อง'
        return
      }
      this.$swal({
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการเปลี่ยนเปอร์เซ็นกินทั้งหมดใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        onOpen: () => {
          // document.activeElement.blur()
          //
          // window.previousActiveElement = null
          const btn = this.$swal.getConfirmButton()
          // eslint-disable-next-line no-shadow
          window.addEventListener('keypress', e => {
            if (e.key === 'Enter') {
              btn.click()
            }
          })
          // btn.scrollIntoView()
        },
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await lottoService.getAuthen(`yeekee/setting/all?Percen=${this.eatForm.percen}`)
            this.dialogEatForm = false
            if (res.status === 200) {
              this.LoadYeeKee()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
                },
              })
            }
          }
        })
        .then(() => {
          window.removeEventListener('keypress', () => {})
        })
    },
    async SubmitForm(e) {
      e.preventDefault()
      if (this.edtForm.percen.length < 0) {
        this.modalTextError = 'คุณยังไม่ได้กรอกข้อมูล'
        return
      }
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(this.edtForm.percen) || this.edtForm.percen[0] === '-') {
        this.modalTextError = 'กรุณากรอกตัวเลขให้ถูกต้อง'
        return
      }
      const res = await lottoService.putAuthen(
        `yeekee/setting/${this.edtForm.id}`,
        {
          percen: this.edtForm.percen * 1,
        },
      )

      this.dialogFormVisible = false

      if (res.status === 200) {
        this.LoadYeeKee()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'success',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'บันทึกข้อมูสำเร็จ',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: 'ขัดคล่อง โปรดลองใหม่ในภายหลัง',
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

@media (max-width: 768px) {

  .cusBtn {
    //margin-bottom: 5px;
    width: 100%;
  }
}
</style>
