<template>
  <div>
    <!--    <b-form-checkbox-->
    <!--      v-model="isAutoUpdateLimitNumber"-->
    <!--      name="check-button"-->
    <!--      class="mb-2"-->
    <!--      switch-->
    <!--      inline-->
    <!--      @input="changeAutoUpdateLimitNumber"-->
    <!--    >-->
    <!--      Auto Update Limit-->
    <!--    </b-form-checkbox>-->
    <b-card>
      <b-tabs
        v-model="lastTab"
        @input="changeTab"
      >
        <b-tab
          title="รัฐบาล"
          lazy
        >
          <LottoInformationTable :type="1" />
        </b-tab>
        <b-tab
          title="หุ้น"
          lazy
        >
          <LottoInformationTable :type="2" />
        </b-tab>
        <b-tab
          title="ยี่กี"
          lazy
        >
          <YeekeeInformationTable :type="3" />
        </b-tab>
        <b-tab
          title="เลขชุด"
          lazy
        >
          <LottoInformationTable :type="4" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'

import LottoInformationTable from '@/views/components/LottoInformationTable.vue'
import YeekeeInformationTable from '@/views/components/YeekeeInformationTable.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    // BFormCheckbox,
    LottoInformationTable,
    YeekeeInformationTable,
  },
  data() {
    return {
      lastTab: 0,
      isAutoUpdateLimitNumber: false,
    }
  },
  beforeMount() {
    const tab = localStorage.getItem('lotto-list-tab')
    if (tab) {
      this.lastTab = tab * 1
    }
  },
  methods: {
    changeTab(e) {
      this.lastTab = e
      localStorage.setItem('lotto-list-tab', this.lastTab)
    },
  },
}
</script>
